import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import Brightness5Icon from '@mui/icons-material/Brightness5'
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment'
import SecurityIcon from '@mui/icons-material/Security'
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500'
import { Box, Grid, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { FC, Fragment } from 'react'
import { TypeBox } from './type-box'

interface Props {
  primaryType?: string
  secondaryType?: string
  sidebarArea?: JSX.Element
  stats: {
    hp: string | number
    attack: string | number
    defense: string | number
    specialAttack: string | number
    specialDefense: string | number
    speed: string | number
    total: string | number
  }
}

export const SlotSidebar: FC<Props> = ({
  primaryType,
  secondaryType,
  sidebarArea,
  stats
}) => (
  <Box
    sx={{
      minWidth: 90,
      width: 90,
      background: grey[600],
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      p: 1,
      border: '1px solid #ddd',
      borderRadius: '0 5px 5px 0',
      borderLeft: 0
    }}
  >
    <Grid container alignItems="center" spacing={0.5}>
      {sidebarArea && (
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'center', mb: 0 }}
        >
          {sidebarArea}
        </Grid>
      )}
      {primaryType && (
        <Grid item xs={12}>
          <TypeBox type={primaryType} active={true} amount={1} />
        </Grid>
      )}
      {secondaryType && (
        <Grid item xs={12}>
          <TypeBox type={secondaryType} active={true} amount={1} />
        </Grid>
      )}
    </Grid>
    <Grid container>
      {[
        { stat: <FavoriteBorderIcon />, value: stats.hp },
        { stat: <LocalFireDepartmentIcon />, value: stats.attack },
        { stat: <SecurityIcon />, value: stats.defense },
        { stat: <AutoFixHighIcon />, value: stats.specialAttack },
        { stat: <Brightness5Icon />, value: stats.specialDefense },
        { stat: <DirectionsRunIcon />, value: stats.speed },
        { stat: <StarBorderPurple500Icon />, value: stats.total }
      ].map(({ stat, value }, index) => (
        <Fragment key={index}>
          <Grid item xs={6}>
            <Typography>{stat}</Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            {value}
          </Grid>
        </Fragment>
      ))}
    </Grid>
  </Box>
)
