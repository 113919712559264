import { RoutePath } from '@root/routes'
import { getHistory } from '@root/utils/history'

interface RouteHookModel {
  path: string
  hook: (...params: Array<string>) => Promise<unknown>
}

const history = getHistory()
const routeHooks: Array<RouteHookModel> = []
let hookReject: (() => void) | undefined

history.listen(() => {
  hookReject && hookReject()
})

const getRouteHook = (path: string): RouteHookModel | undefined =>
  routeHooks.find((routeHook) => routeHook.path === path)

export const registerRouteHook = (
  path: string,
  hook: (...params: Array<string>) => Promise<unknown>
) => {
  const routeHook = getRouteHook(path)

  if (routeHook) {
    routeHook.hook = hook
  } else {
    routeHooks.push({ path, hook })
  }
}

export const changeRoute = async (
  path: RoutePath,
  params: Record<string, string> = {}
) => {
  const routeHook = getRouteHook(path)

  if (hookReject) {
    hookReject()
  }

  const translatedPath = Object.keys(params).reduce(
    (_path, param) => _path.replace(`:${param}`, params[param]),
    path
  )

  if (routeHook) {
    new Promise((resolve, reject) => {
      hookReject = reject
      routeHook
        .hook(...Object.keys(params).map((param) => params[param]))
        .then(resolve)
    })
      .then(() => history.push(translatedPath))
      .catch()
  } else {
    history.push(translatedPath)
  }
}
