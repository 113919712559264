//import FaceIcon from '@mui/icons-material/Face'
import { Box } from '@mui/material'
import { FC } from 'react'
import { typeColors } from '../constants/type-colors'

interface Props {
  type: string
  active: boolean
  amount: number
  onClick?: () => void
}

export const TypeBox: FC<Props> = ({ type, active, amount, onClick }) => (
  <Box
    sx={{
      position: 'relative',
      py: 0.5,
      borderRadius: 1,
      background:
        (active && typeColors.find((_type) => _type.type === type)?.color) ||
        '',
      border: '2px solid rgba(0,0,0,.2)',
      fontSize: 12,
      color: active ? 'white' : '#666',
      textShadow: active ? '1px 1px 2px rgb(0 0 0 / 70%)' : '',
      textTransform: 'uppercase',
      textAlign: 'center',
      cursor: onClick && 'pointer'
    }}
    onClick={onClick}
  >
    {type}
    {active && amount > 1 && (
      <Box
        sx={{
          position: 'absolute',
          left: '38%',
          borderRadius: '100%',
          background: 'white',
          textShadow: 'none',
          boxShadow: '0 1px 3px 0 #aaa',
          color: 'black',
          width: 18,
          height: 18
        }}
      >
        {amount}
      </Box>
    )}
  </Box>
)
