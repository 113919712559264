import AddIcon from '@mui/icons-material/Add'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import {
  Avatar,
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { FC, memo } from 'react'
import { TypeBox } from '@root/components/roster/components/type-box'
import { PokemonViewModel } from '../models/pokemon-view'

interface Props {
  pokemonList: Array<PokemonViewModel>
  mayAdd: boolean
  addPokemon: (pokemonId: number) => void
}

const PokemonTable: FC<Props> = memo(({ pokemonList, mayAdd, addPokemon }) => (
  <TableContainer sx={{ maxHeight: 567, overflowY: 'scroll' }}>
    <Table stickyHeader size="small">
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox"></TableCell>
          <TableCell padding="checkbox"></TableCell>
          <TableCell padding="checkbox"></TableCell>
          <TableCell>Name</TableCell>
          <TableCell width="200px">Types</TableCell>
          <TableCell width="375px">Abilities</TableCell>
          {['HP', 'Atk', 'Def', 'Sp. Atk', 'Sp. Def', 'Spd'].map(
            (stat, index) => (
              <TableCell key={index} width="55px" sx={{ p: 0 }}>
                {stat}
              </TableCell>
            )
          )}
          <TableCell padding="checkbox" />
        </TableRow>
      </TableHead>
      <TableBody>
        {pokemonList.map((pokemon, index) => (
          <TableRow key={index} hover>
            <TableCell>#{pokemon.speciesId}</TableCell>
            <TableCell>
              {pokemon.regularPokedex && <CheckCircleOutlineIcon />}
            </TableCell>
            <TableCell>
              <Avatar sx={{ bgcolor: grey[300] }}>
                <Box
                  component="img"
                  alt=""
                  src={pokemon.imageUrl}
                  sx={{ maxWidth: 32, maxHeight: 32 }}
                />
              </Avatar>
            </TableCell>
            <TableCell>{pokemon.name}</TableCell>
            <TableCell>
              <Grid container spacing={1}>
                {pokemon.types.map((type, typeIndex) => (
                  <Grid key={typeIndex} item xs={6}>
                    <TypeBox type={type} active amount={1} />
                  </Grid>
                ))}
              </Grid>
            </TableCell>
            <TableCell>{pokemon.abilities.join(', ')}</TableCell>
            {pokemon.stats.map((stat, statIndex) => (
              <TableCell key={statIndex} width="55px" sx={{ p: 0 }}>
                {stat}
              </TableCell>
            ))}
            <TableCell sx={{ pr: 1 }}>
              <Button
                variant="outlined"
                endIcon={<AddIcon />}
                disabled={!mayAdd}
                onClick={() => addPokemon(pokemon.id)}
              >
                Add
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
))

export default PokemonTable
