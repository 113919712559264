import { FilterStatsModel } from '@shared/models/filter-stats'
import { initialState, usePokePickerStore } from './store'

const { getState, setState } = usePokePickerStore

export const resetFilter = () => setState({ ...initialState })
export const setSearchQuery = (searchQuery: string) => setState({ searchQuery })
export const selectType = (typeId: number) => {
  const { selectedTypeIds } = getState()

  setState({
    selectedTypeIds: selectedTypeIds.find(
      (selectedTypeId) => selectedTypeId === typeId
    )
      ? selectedTypeIds.filter((selectedTypeId) => selectedTypeId !== typeId)
      : [...selectedTypeIds, typeId]
  })
}

export const setFilterStat = (stat: keyof FilterStatsModel, value: number) =>
  setState({ filterStats: { ...getState().filterStats, [stat]: value } })
