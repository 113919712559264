import { green, grey, purple } from '@mui/material/colors'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { FC } from 'react'
import { Shell } from './components/shell'

const theme = createTheme({
  palette: {
    primary: {
      main: purple[500]
    },
    secondary: {
      main: green[500]
    },
    background: {
      default: grey[100]
    }
  },
  typography: {
    fontFamily: ['Fira Sans'].join(',')
  }
})

export const Layout: FC = ({ children }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Shell>{children}</Shell>
  </ThemeProvider>
)
