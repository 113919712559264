import { setEditableRosterData } from '@root/pages/modify-roster/store/actions'
import { endpoints } from '@root/transport/http/endpoints'
import { changeRoute } from '@root/utils/routes'
import { initialState, useRosterListStore } from './store'

const { getState, setState } = useRosterListStore

export const resetRosterList = () => setState({ ...initialState })
export const fetchRosters = async () => {
  const { versionRosters, availableVersions } =
    await endpoints.getRosters.dispatch()

  setState({
    viewState: 'viewing',
    versionRosters,
    availableVersions
  })
}

export const openCreateRosterDialog = () =>
  setState({ viewState: 'viewing-create-roster-dialog' })

export const selectVersionId = (versionId: number) =>
  setState({ selectedVersionId: versionId })

export const setRosterName = (rosterName: string) => setState({ rosterName })
export const createRoster = async () => {
  const { selectedVersionId, rosterName } = getState()
  const rosterData = await endpoints.createRoster.dispatch({
    versionId: selectedVersionId,
    name: rosterName
  })

  setEditableRosterData(rosterData)

  changeRoute('/modify-roster/:id', { id: rosterData.roster.id })
}

export const closeRosterDialog = () => {
  setState({
    viewState: 'viewing',
    selectedVersionId: initialState.selectedVersionId,
    rosterName: initialState.rosterName
  })
}

export const loadModifyRoster = () =>
  setState({ viewState: 'loading-modify-roster' })
