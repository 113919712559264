import { Grid, Slider, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { Box } from '@mui/system'
import { FC } from 'react'
import { FilterStatsModel } from '@shared/models/filter-stats'
import { setFilterStat } from '../store/actions'

interface Props {
  stats: FilterStatsModel
  setStats: (stats: Partial<FilterStatsModel>) => void
}

const statLabels: Array<{ label: string; key: keyof FilterStatsModel }> = [
  { label: 'HP', key: 'hp' },
  { label: 'Attack', key: 'attack' },
  { label: 'Defense', key: 'defense' },
  { label: 'Special Attack', key: 'specialAttack' },
  { label: 'Special Defense', key: 'specialDefense' },
  { label: 'Speed', key: 'speed' },
  { label: 'Total', key: 'total' }
]

export const StatFilter: FC<Props> = ({ stats, setStats }) => (
  <Grid container spacing={8}>
    {statLabels.map((stat, index) => (
      <Grid key={index} item xs>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Box component={Typography} variant="caption" color={grey[600]}>
            {stat.label}
          </Box>
          <Box sx={{ minWidth: 15, textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              {stats[stat.key]
                ? `${stat.key === 'total' ? '<=' : '>='} ${stats[stat.key]}`
                : '∞'}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Slider
            size="small"
            step={stat.key === 'total' ? 20 : 5}
            marks
            min={stat.key === 'total' ? 400 : 0}
            max={stat.key === 'total' ? 800 : 140}
            value={stats[stat.key]}
            onChange={(e, value) => setStats({ [stat.key]: value as number })}
            onChangeCommitted={(e, value) =>
              setFilterStat(stat.key, value as number)
            }
            sx={{ py: 0.5 }}
          />
        </Box>
      </Grid>
    ))}
  </Grid>
)
