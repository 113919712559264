import create from 'zustand'
import { VersionRosterModel } from '@shared/models/roster/viewable/version-roster'

export interface RosterListState {
  viewState:
    | 'viewing'
    | 'fetching-rosters'
    | 'loading-modify-roster'
    | 'viewing-create-roster-dialog'
    | 'creating-roster'
  versionRosters: Array<VersionRosterModel>
  availableVersions: Array<any>
  selectedVersionId: number
  rosterName: string
}

export const initialState: RosterListState = {
  viewState: 'fetching-rosters',
  versionRosters: [],
  availableVersions: [],
  selectedVersionId: 0,
  rosterName: ''
}

export const useRosterListStore = create<RosterListState>(() => initialState)
