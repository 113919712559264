"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAttackTypes = void 0;
const getAttackTypes = (slotMoves, typeEfficacies) => slotMoves
    .flat()
    .filter((move) => move.category !== 'status')
    .reduce((moveAttackTypes, move) => moveAttackTypes.map((attackType) => attackType.id === move.typeId
    ? { ...attackType, amount: attackType.amount + 1 }
    : attackType), typeEfficacies.map((type) => ({
    id: type.id,
    name: type.name,
    amount: 0
})))
    .map((attackType) => ({ type: attackType.name, amount: attackType.amount }));
exports.getAttackTypes = getAttackTypes;
