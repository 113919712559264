import 'reflect-metadata'
import { FC } from 'react'
import { Route, Router, Switch } from 'react-router'
import { hasPresentKey } from 'ts-is-present'
import { Layout } from './layout/layout'
import { routes } from './routes'
import { endpoints } from './transport/http/endpoints'
import { setupEndpoints } from './transport/http/setup'
import { getHistory } from './utils/history'
import { registerRouteHook } from './utils/routes'

window.onhashchange = () => {
  const { hash } = window.location

  if (hash) {
    localStorage.setItem('token', hash.split('=').pop() || '')
    window.location.assign(
      `${window.location.origin}${window.location.pathname}`
    )
  }
}

// FIXME
setupEndpoints(endpoints as any)

routes
  .filter(hasPresentKey('hook'))
  .map((route) => registerRouteHook(route.path, route.hook))

export const App: FC = () => (
  <Router history={getHistory()}>
    <Layout>
      <Switch>
        {routes.map((route, index) => (
          <Route key={index} exact path={route.path}>
            <route.component />
          </Route>
        ))}
      </Switch>
    </Layout>
  </Router>
)
