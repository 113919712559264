import { ClientEndpointCollection } from '@shared/transport/http/endpoints'
import { mockEndpoints } from './mock-endpoints/mock-endpoints'
import { dispatch } from './request'

export const endpoints: ClientEndpointCollection<AbortSignal> =
  process.env.REACT_APP_MOCK === 'true'
    ? mockEndpoints
    : {
        test: { method: 'get', dispatch },
        getRosters: { method: 'get', dispatch },
        getEditableRosterData: { method: 'get', dispatch },
        createRoster: { method: 'post', dispatch },
        slots: {
          setPokemon: { method: 'put', dispatch },
          setVerdict: { method: 'put', dispatch },
          setRole: { method: 'put', dispatch },
          setLocation: { method: 'put', dispatch },
          setAbility: { method: 'put', dispatch },
          setItem: { method: 'put', dispatch },
          setMove: { method: 'put', dispatch },
          remove: { method: 'delete', dispatch }
        }
      }
