import { TextField } from '@mui/material'
import { FC, useState } from 'react'
import { EditableRosterSlotViewModel } from '../../../models/editable-roster-slot-view'
import { setLocation, setRole } from '../../../store/actions'

interface Props {
  slot: EditableRosterSlotViewModel
  variant: 'role' | 'location'
}

export const SlotField: FC<Props> = ({ slot, variant }) => {
  const [localValue, setLocalValue] = useState(slot[variant])

  return (
    <TextField
      label={variant === 'role' ? 'Role' : 'Location'}
      value={localValue}
      variant="standard"
      onChange={(e) => {
        setLocalValue(e.target.value)

        if (variant === 'role') {
          setRole(slot.slotOrder, e.target.value)
        } else {
          setLocation(slot.slotOrder, e.target.value)
        }
      }}
    />
  )
}
