import {
  Box,
  Button,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'
import { FC } from 'react'
import { MoveCategoryModel } from '@shared/models/move-category'
import { TypeEfficaciesPowerModel } from '@shared/models/types/type-efficacies-power'
import { MoveCategoryImage } from '@root/components/move-category-image'
import { TypeBox } from '@root/components/roster/components/type-box'
import {
  resetFilter,
  selectCategory,
  selectType,
  setSearchQuery
} from '../store/actions'
import { useTypes } from '../store/selectors'
import { useMovePickerStore } from '../store/store'

interface Props {
  availableTypes: Array<TypeEfficaciesPowerModel>
}

const moveCategories: Array<MoveCategoryModel> = [
  'physical',
  'special',
  'status'
]

export const MoveFilter: FC<Props> = ({ availableTypes }) => {
  const [searchQuery, category] = useMovePickerStore((state) => [
    state.searchQuery,
    state.category
  ])
  const types = useTypes(availableTypes)

  return (
    <>
      <Grid item xs={4}>
        <TextField
          variant="standard"
          label="Search name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ textAlign: 'center' }}>
          <ToggleButtonGroup
            value={category}
            exclusive
            onChange={(e, nextCategory) => selectCategory(nextCategory)}
          >
            {moveCategories.map((moveCategory, index) => (
              <ToggleButton key={index} value={moveCategory}>
                <MoveCategoryImage category={moveCategory} />
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Grid
          container
          spacing={1}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item>
            <Button variant="contained" onClick={resetFilter}>
              Reset
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={0.5}>
          {types.map((type, index) => (
            <Grid key={index} item xs sx={{ minWidth: 90 }}>
              <TypeBox
                type={type.name}
                active={type.selected}
                amount={1}
                onClick={() => selectType(type.id)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  )
}
