import { Slider } from '@mui/material'
import { FC, useState } from 'react'
import { Verdicts } from '@root/components/roster/verdicts'
import { EditableRosterSlotViewModel } from '@root/pages/modify-roster/models/editable-roster-slot-view'
import { setVerdict } from '@root/pages/modify-roster/store/actions'

interface Props {
  slot: EditableRosterSlotViewModel
}

export const EditableVerdicts: FC<Props> = ({ slot }) => {
  const [verdicts, setVerdicts] = useState(slot.verdicts)

  return (
    <Verdicts
      verdicts={verdicts}
      valueCreator={(category, value) => (
        <Slider
          size="small"
          step={1}
          marks
          min={0}
          max={10}
          onChange={(e, _value) =>
            setVerdicts({ ...verdicts, [category]: _value as number })
          }
          onChangeCommitted={(e, _value) =>
            setVerdict(slot.slotOrder, category, _value as number)
          }
          value={verdicts[category]}
          sx={{ py: 0.5 }}
        />
      )}
    />
  )
}
