import { FC, memo } from 'react'
import { MoveTable } from './move-table'
import { MoveListModel } from '../models/move-list'

interface Props {
  open: boolean
  moves: MoveListModel
  setMove: (id: number, typeId: number | undefined) => void
}

export const MoveList: FC<Props> = memo(
  ({ moves, setMove }) => (
    <>
      <MoveTable
        title="Level"
        moves={moves.byLevel}
        extraColumn="Lv."
        extraColumnValue={(move) =>
          (
            (move.method.variant === 'level-up' && move.method.level) ||
            '-'
          ).toString()
        }
        setMove={setMove}
      />
      <MoveTable
        title="Technical"
        moves={moves.byTechnical}
        extraColumn="ID"
        extraColumnValue={(move) =>
          (
            (move.method.variant === 'technical' && move.method.id) ||
            '-'
          ).toString()
        }
        setMove={setMove}
      />
      <MoveTable title="Tutor" moves={moves.byTutor} setMove={setMove} />
      <MoveTable title="Egg" moves={moves.byEgg} setMove={setMove} />
    </>
  ),
  (prevProps, nextProps) => !nextProps.open
)
