import { FC } from 'react'
import { ModifyRoster } from './pages/modify-roster/modify-roster'
import { fetchEditableRosterData } from './pages/modify-roster/store/actions'
import { RosterList } from './pages/roster-list/roster-list'
import { fetchRosters } from './pages/roster-list/store/actions'

export const routes: Array<{
  path: string
  component: FC
  hook?: (...params: Array<string>) => Promise<void>
}> = [
  {
    path: <const>'/',
    component: RosterList,
    hook: fetchRosters
  },
  {
    path: <const>'/modify-roster/:id',
    component: ModifyRoster,
    hook: (id: string) => fetchEditableRosterData(id)
  }
]

const paths = routes.map((route) => route.path)

export type RoutePath = typeof paths[number]
