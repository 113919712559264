import { EditableRosterDataModel } from '@shared/transport/http/collection/collection'
import { ClientEndpoint } from '@shared/transport/http/endpoints'
import { editableRosterData } from '../constants/editable-roster-data'

export const getEditableRosterData: ClientEndpoint<
  AbortSignal,
  'get',
  string,
  EditableRosterDataModel
> = {
  method: 'get',
  dispatch: async () => editableRosterData
}
