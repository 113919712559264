import { EditableRosterDataModel } from '@shared/transport/http/collection/collection'

export const editableRosterData: EditableRosterDataModel = {
  versionId: 16,
  roster: {
    id: 'abc-123',
    name: 'Test Roster',
    slots: [
      {
        id: 6,
        slotOrder: 1,
        verdicts: {
          earlyGame: 5,
          midGame: 8,
          endGame: 9
        },
        role: 'Special Sweeper',
        location: 'Pt 2 (Fiery Mountain)',
        abilityId: 1,
        itemId: 1,
        moves: [
          { slotOrder: 1, id: 1, typeId: undefined },
          { slotOrder: 3, id: 3, typeId: undefined },
          { slotOrder: 4, id: 4, typeId: undefined }
        ]
      }
    ]
  },
  abilities: [
    { id: 0, name: 'Blaze' },
    { id: 1, name: 'Solar Power' },
    { id: 2, name: 'Compound Eyes' },
    { id: 3, name: 'Tinted Lens' },
    { id: 4, name: 'Keen Eye' },
    { id: 5, name: 'Tangeled Feet' },
    { id: 6, name: 'Big Pecks' },
    { id: 7, name: 'Sniper' },
    { id: 8, name: 'Flash Fire' },
    { id: 9, name: 'Drought' },
    { id: 10, name: 'Inner Focus' },
    { id: 11, name: 'Infiltrator' },
    { id: 12, name: 'Intimidate' },
    { id: 13, name: 'Justified' },
    { id: 14, name: 'Run Away' },
    { id: 15, name: 'Flame Body' },
    { id: 16, name: 'Defiant' },
    { id: 17, name: 'Early Bird' },
    { id: 18, name: 'Swarm' },
    { id: 19, name: 'Technician' },
    { id: 20, name: 'Steadfast' },
    { id: 21, name: 'Vital Spirit' },
    { id: 22, name: 'Moxie' },
    { id: 23, name: 'Guts' },
    { id: 24, name: 'Rock Head' },
    { id: 25, name: 'Pressure' },
    { id: 26, name: 'Unnerve' },
    { id: 27, name: 'Snow Cloak' },
    { id: 28, name: 'Static' },
    { id: 29, name: 'Multiscale' },
    { id: 30, name: 'No Guard' }
  ],
  moves: [
    {
      id: 1,
      name: 'Wing Attack',
      typeId: 10,
      category: 'physical',
      power: 60
    },
    { id: 2, name: 'Growl', typeId: 1, category: 'status', power: 0 },
    { id: 3, name: 'Smokescreen', typeId: 1, category: 'status', power: 0 },
    { id: 4, name: 'Ember', typeId: 2, category: 'special', power: 40 },
    {
      id: 5,
      name: 'Fury Swipes',
      typeId: 1,
      category: 'physical',
      power: 18
    },
    {
      id: 6,
      name: 'Dragon Rage',
      typeId: 15,
      category: 'special',
      power: 0
    },
    { id: 7, name: 'Fire Spin', typeId: 2, category: 'special', power: 35 },
    { id: 8, name: 'Slash', typeId: 1, category: 'physical', power: 70 },
    {
      id: 9,
      name: 'Flamethrower',
      typeId: 2,
      category: 'special',
      power: 90
    },
    {
      id: 10,
      name: 'Air Slash',
      typeId: 10,
      category: 'physical',
      power: 70
    },
    {
      id: 11,
      name: 'Flare Blitz',
      typeId: 2,
      category: 'physical',
      power: 120
    },
    { id: 12, name: 'Focus Energy', typeId: 1, category: 'status', power: 0 },
    { id: 13, name: 'Bide', typeId: 1, category: 'physical', power: 0 },
    {
      id: 14,
      name: 'Seismic Toss',
      typeId: 7,
      category: 'physical',
      power: 0
    },
    {
      id: 15,
      name: 'Karate Chop',
      typeId: 7,
      category: 'physical',
      power: 50
    },
    {
      id: 16,
      name: 'Brick Break',
      typeId: 7,
      category: 'physical',
      power: 75
    },
    {
      id: 17,
      name: 'Submission',
      typeId: 7,
      category: 'physical',
      power: 80
    },
    { id: 18, name: 'Bulk Up', typeId: 7, category: 'status', power: 0 },
    {
      id: 19,
      name: 'Superpower',
      typeId: 7,
      category: 'physical',
      power: 120
    },
    { id: 20, name: 'Swagger', typeId: 1, category: 'status', power: 0 },
    {
      id: 21,
      name: 'Solar Beam',
      typeId: 5,
      category: 'special',
      power: 120
    },
    {
      id: 22,
      name: 'Dragon Pulse',
      typeId: 15,
      category: 'special',
      power: 85
    },
    { id: 23, name: 'Swords Dance', typeId: 1, category: 'status', power: 0 },
    {
      id: 24,
      name: 'Fire Punch',
      typeId: 2,
      category: 'physical',
      power: 75
    },
    {
      id: 25,
      name: 'Earthquake',
      typeId: 9,
      category: 'physical',
      power: 100
    },
    {
      id: 26,
      name: 'Cross Chop',
      typeId: 7,
      category: 'physical',
      power: 100
    },
    {
      id: 27,
      name: 'Blast Burn',
      typeId: 2,
      category: 'special',
      power: 150
    }
  ],
  items: [
    { id: 1, name: 'Leftovers' },
    { id: 2, name: 'Choice Specs' },
    { id: 3, name: 'Oran Berry' }
  ],
  pokemon: [
    {
      id: 6,
      speciesId: 6,
      regularPokedex: true,
      name: 'Charizard',
      form: undefined,
      primaryTypeId: 2,
      secondaryTypeId: 10,
      abilityIds: [0, 1],
      moves: [
        { id: 1, method: { variant: 'level-up', level: 0 } },
        { id: 2, method: { variant: 'level-up', level: 4 } },
        { id: 3, method: { variant: 'level-up', level: 9 } },
        { id: 4, method: { variant: 'level-up', level: 13 } },
        { id: 5, method: { variant: 'level-up', level: 20 } },
        { id: 6, method: { variant: 'level-up', level: 26 } },
        { id: 7, method: { variant: 'level-up', level: 33 } },
        { id: 8, method: { variant: 'level-up', level: 43 } },
        { id: 9, method: { variant: 'level-up', level: 54 } },
        { id: 10, method: { variant: 'level-up', level: 62 } },
        { id: 11, method: { variant: 'level-up', level: 73 } },
        { id: 16, method: { variant: 'technical', id: 'TM13' } },
        { id: 21, method: { variant: 'technical', id: 'TM45' } },
        { id: 22, method: { variant: 'technical', id: 'TM34' } },
        { id: 24, method: { variant: 'technical', id: 'TM31' } },
        { id: 25, method: { variant: 'technical', id: 'TM41' } },
        { id: 27, method: { variant: 'tutor' } }
      ],
      stats: {
        hp: 78,
        attack: 84,
        defense: 78,
        specialAttack: 109,
        specialDefense: 85,
        speed: 100
      }
    },
    {
      id: 12,
      speciesId: 2,
      regularPokedex: true,
      name: 'Butterfree',
      form: undefined,
      primaryTypeId: 12,
      secondaryTypeId: 10,
      abilityIds: [2, 3],
      moves: [],
      stats: {
        hp: 60,
        attack: 45,
        defense: 50,
        specialAttack: 90,
        specialDefense: 80,
        speed: 70
      }
    },
    {
      id: 18,
      speciesId: 8,
      regularPokedex: true,
      name: 'Pidgeot',
      form: undefined,
      primaryTypeId: 1,
      secondaryTypeId: 10,
      abilityIds: [4, 5, 6],
      moves: [],
      stats: {
        hp: 83,
        attack: 80,
        defense: 75,
        specialAttack: 70,
        specialDefense: 70,
        speed: 101
      }
    },
    {
      id: 22,
      speciesId: 2,
      regularPokedex: true,
      name: 'Fearow',
      form: undefined,
      primaryTypeId: 1,
      secondaryTypeId: 10,
      abilityIds: [4, 7],
      moves: [],
      stats: {
        hp: 65,
        attack: 90,
        defense: 65,
        specialAttack: 61,
        specialDefense: 61,
        speed: 100
      }
    },
    {
      id: 38,
      speciesId: 8,
      regularPokedex: true,
      name: 'Ninetales',
      form: undefined,
      primaryTypeId: 2,
      secondaryTypeId: undefined,
      abilityIds: [8, 9],
      moves: [],
      stats: {
        hp: 73,
        attack: 76,
        defense: 75,
        specialAttack: 81,
        specialDefense: 100,
        speed: 100
      }
    },
    {
      id: 42,
      speciesId: 2,
      regularPokedex: true,
      name: 'Golbat',
      form: undefined,
      primaryTypeId: 8,
      secondaryTypeId: 10,
      abilityIds: [10, 11],
      moves: [],
      stats: {
        hp: 75,
        attack: 80,
        defense: 70,
        specialAttack: 65,
        specialDefense: 75,
        speed: 90
      }
    },
    {
      id: 59,
      speciesId: 9,
      regularPokedex: true,
      name: 'Arcanine',
      form: undefined,
      primaryTypeId: 2,
      secondaryTypeId: undefined,
      abilityIds: [12, 8, 13],
      moves: [],
      stats: {
        hp: 90,
        attack: 110,
        defense: 80,
        specialAttack: 100,
        specialDefense: 80,
        speed: 95
      }
    },
    {
      id: 68,
      speciesId: 8,
      regularPokedex: true,
      name: 'Machamp',
      form: undefined,
      primaryTypeId: 7,
      secondaryTypeId: undefined,
      abilityIds: [23, 30, 20],
      moves: [
        { id: 12, method: { variant: 'level-up', level: 4 } },
        { id: 13, method: { variant: 'level-up', level: 8 } },
        { id: 14, method: { variant: 'level-up', level: 12 } },
        { id: 15, method: { variant: 'level-up', level: 16 } },
        { id: 16, method: { variant: 'level-up', level: 21 } },
        { id: 17, method: { variant: 'level-up', level: 26 } },
        { id: 18, method: { variant: 'level-up', level: 37 } },
        { id: 19, method: { variant: 'level-up', level: 48 } },
        { id: 20, method: { variant: 'egg' } },
        { id: 23, method: { variant: 'egg' } },
        { id: 24, method: { variant: 'technical', id: 'TM31' } },
        { id: 25, method: { variant: 'technical', id: 'TM41' } },
        { id: 26, method: { variant: 'egg' } }
      ],
      stats: {
        hp: 90,
        attack: 130,
        defense: 80,
        specialAttack: 65,
        specialDefense: 85,
        speed: 55
      }
    },
    {
      id: 78,
      speciesId: 8,
      regularPokedex: true,
      name: 'Rapidash',
      form: undefined,
      primaryTypeId: 2,
      secondaryTypeId: undefined,
      abilityIds: [14, 8, 15],
      moves: [],
      stats: {
        hp: 65,
        attack: 100,
        defense: 70,
        specialAttack: 80,
        specialDefense: 80,
        speed: 105
      }
    },
    {
      id: 83,
      speciesId: 3,
      regularPokedex: true,
      name: "Farfetch'd",
      form: undefined,
      primaryTypeId: 1,
      secondaryTypeId: 10,
      abilityIds: [4, 10, 16],
      moves: [],
      stats: {
        hp: 52,
        attack: 90,
        defense: 55,
        specialAttack: 58,
        specialDefense: 62,
        speed: 60
      }
    },
    {
      id: 85,
      speciesId: 5,
      regularPokedex: true,
      name: 'Dodrio',
      form: undefined,
      primaryTypeId: 1,
      secondaryTypeId: 10,
      abilityIds: [14, 17, 5],
      moves: [],
      stats: {
        hp: 60,
        attack: 110,
        defense: 70,
        specialAttack: 60,
        specialDefense: 60,
        speed: 110
      }
    },
    {
      id: 123,
      speciesId: 3,
      regularPokedex: true,
      name: 'Scyther',
      form: undefined,
      primaryTypeId: 12,
      secondaryTypeId: 10,
      abilityIds: [18, 19, 20],
      moves: [],
      stats: {
        hp: 70,
        attack: 110,
        defense: 80,
        specialAttack: 55,
        specialDefense: 80,
        speed: 105
      }
    },
    {
      id: 126,
      speciesId: 6,
      regularPokedex: true,
      name: 'Mamgar',
      form: undefined,
      primaryTypeId: 2,
      secondaryTypeId: undefined,
      abilityIds: [15, 21],
      moves: [],
      stats: {
        hp: 65,
        attack: 95,
        defense: 57,
        specialAttack: 100,
        specialDefense: 85,
        speed: 93
      }
    },
    {
      id: 130,
      speciesId: 0,
      regularPokedex: true,
      name: 'Gyarados',
      form: undefined,
      primaryTypeId: 3,
      secondaryTypeId: 10,
      abilityIds: [12, 22],
      moves: [],
      stats: {
        hp: 95,
        attack: 125,
        defense: 79,
        specialAttack: 60,
        specialDefense: 100,
        speed: 81
      }
    },
    {
      id: 136,
      speciesId: 6,
      regularPokedex: true,
      name: 'Flareon',
      form: undefined,
      primaryTypeId: 2,
      secondaryTypeId: undefined,
      abilityIds: [8, 23],
      moves: [],
      stats: {
        hp: 65,
        attack: 130,
        defense: 60,
        specialAttack: 95,
        specialDefense: 110,
        speed: 65
      }
    },
    {
      id: 142,
      speciesId: 2,
      regularPokedex: true,
      name: 'Aerodactyl',
      form: undefined,
      primaryTypeId: 13,
      secondaryTypeId: 10,
      abilityIds: [24, 25, 26],
      moves: [],
      stats: {
        hp: 80,
        attack: 105,
        defense: 65,
        specialAttack: 60,
        specialDefense: 75,
        speed: 130
      }
    },
    {
      id: 144,
      speciesId: 4,
      regularPokedex: true,
      name: 'Articuno',
      form: undefined,
      primaryTypeId: 6,
      secondaryTypeId: 10,
      abilityIds: [25, 27],
      moves: [],
      stats: {
        hp: 90,
        attack: 85,
        defense: 100,
        specialAttack: 95,
        specialDefense: 125,
        speed: 85
      }
    },
    {
      id: 145,
      speciesId: 5,
      regularPokedex: true,
      name: 'Zapdos',
      form: undefined,
      primaryTypeId: 4,
      secondaryTypeId: 10,
      abilityIds: [25, 28],
      moves: [],
      stats: {
        hp: 90,
        attack: 90,
        defense: 85,
        specialAttack: 125,
        specialDefense: 90,
        speed: 100
      }
    },
    {
      id: 146,
      speciesId: 6,
      regularPokedex: true,
      name: 'Moltres',
      form: undefined,
      primaryTypeId: 2,
      secondaryTypeId: 10,
      abilityIds: [25, 15],
      moves: [],
      stats: {
        hp: 90,
        attack: 100,
        defense: 90,
        specialAttack: 125,
        specialDefense: 85,
        speed: 90
      }
    },
    {
      id: 149,
      speciesId: 9,
      regularPokedex: true,
      name: 'Dragonite',
      form: undefined,
      primaryTypeId: 15,
      secondaryTypeId: 10,
      abilityIds: [10, 29],
      moves: [],
      stats: {
        hp: 91,
        attack: 134,
        defense: 95,
        specialAttack: 100,
        specialDefense: 100,
        speed: 80
      }
    }
  ],
  types: [
    { id: 1, name: 'Normal', efficacies: [] },
    { id: 2, name: 'Fire', efficacies: [] },
    { id: 3, name: 'Water', efficacies: [] },
    { id: 4, name: 'Electric', efficacies: [] },
    { id: 5, name: 'Grass', efficacies: [] },
    { id: 6, name: 'Ice', efficacies: [] },
    { id: 7, name: 'Fighting', efficacies: [] },
    { id: 8, name: 'Poison', efficacies: [] },
    { id: 9, name: 'Ground', efficacies: [] },
    { id: 10, name: 'Flying', efficacies: [] },
    { id: 11, name: 'Psychic', efficacies: [] },
    { id: 12, name: 'Bug', efficacies: [] },
    { id: 13, name: 'Rock', efficacies: [] },
    { id: 14, name: 'Ghost', efficacies: [] },
    { id: 15, name: 'Dragon', efficacies: [] },
    { id: 16, name: 'Dark', efficacies: [] },
    { id: 17, name: 'Steel', efficacies: [] }
  ]
}
