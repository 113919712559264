import { Box, Grid, Paper, Typography } from '@mui/material'
import { FC } from 'react'
import { TypeAmountColorModel } from '@root/models/type-amount-color'
import { TypeBox } from './components/type-box'

interface Props {
  name: string
  topRightArea: JSX.Element
  slots: Array<JSX.Element>
  attackTypes: Array<TypeAmountColorModel>
  weaknesses: Array<TypeAmountColorModel>
}

export const Roster: FC<Props> = ({
  name,
  topRightArea,
  slots,
  attackTypes,
  weaknesses
}) => (
  <Paper>
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">{name}</Typography>
            </Grid>
            <Grid item>{topRightArea}</Grid>
          </Grid>
        </Grid>
        {slots.map((slot, index) => (
          <Grid key={index} item xs={4}>
            {slot}
          </Grid>
        ))}
        <Grid item xs={12}>
          <Box sx={{ p: 0 }}>
            <Box component={Typography} sx={{ mb: 1, textAlign: 'center' }}>
              Attack types
            </Box>
            <Grid container spacing={0.5}>
              {attackTypes.map((attackType, index) => (
                <Grid key={index} item xs>
                  <TypeBox
                    type={attackType.type}
                    active={attackType.amount > 0}
                    amount={attackType.amount}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ p: 0 }}>
            <Box component={Typography} sx={{ mb: 1, textAlign: 'center' }}>
              Weaknesses
            </Box>
            <Grid container spacing={0.5}>
              {weaknesses.map((weakness, index) => (
                <Grid key={index} item xs>
                  <TypeBox
                    type={weakness.type}
                    active={weakness.amount > 0}
                    amount={weakness.amount}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  </Paper>
)
