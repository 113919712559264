import { MoveCategoryModel } from '@shared/models/move-category'
import { initialState, useMovePickerStore } from './store'

const { getState, setState } = useMovePickerStore

export const resetFilter = () => setState({ ...initialState })
export const setVariant = (variant: 'regular' | 'specific') =>
  setState({ variant })

export const setSearchQuery = (searchQuery: string) => setState({ searchQuery })
export const selectCategory = (category: MoveCategoryModel) =>
  setState({
    category: category === getState().category ? undefined : category
  })

export const selectType = (typeId: number) => {
  const { selectedTypeIds } = getState()

  setState({
    selectedTypeIds: selectedTypeIds.find(
      (selectedTypeId) => selectedTypeId === typeId
    )
      ? selectedTypeIds.filter((selectedTypeId) => selectedTypeId !== typeId)
      : [...selectedTypeIds, typeId]
  })
}
