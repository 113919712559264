import { ClientEndpointCollection } from '@shared/transport/http/endpoints'
import { getEditableRosterData } from './collection/get-editable-roster-data'
import { getRosters } from './collection/get-rosters'
import { editableRosterData } from './constants/editable-roster-data'

export const mockEndpoints: ClientEndpointCollection<AbortSignal> = {
  test: {
    method: 'get',
    dispatch: async () => 'test'
  },
  getRosters,
  getEditableRosterData,
  createRoster: {
    method: 'post',
    dispatch: async ({ name }) => ({
      ...editableRosterData,
      roster: { ...editableRosterData.roster, name, slots: [] }
    })
  },
  slots: {
    setPokemon: { method: 'put', dispatch: async () => undefined },
    setVerdict: { method: 'put', dispatch: async () => undefined },
    setRole: { method: 'put', dispatch: async () => undefined },
    setLocation: { method: 'put', dispatch: async () => undefined },
    setAbility: { method: 'put', dispatch: async () => undefined },
    setItem: { method: 'put', dispatch: async () => undefined },
    setMove: { method: 'put', dispatch: async () => undefined },
    remove: { method: 'delete', dispatch: async () => undefined }
  }
}
