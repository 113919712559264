import create from 'zustand'
import { FilterStatsModel } from '@shared/models/filter-stats'
import { baseFilterStats } from '../constants/base-filter-stats'

export interface PokePickerState {
  searchQuery: string
  selectedTypeIds: Array<number>
  filterStats: FilterStatsModel
}

export const initialState: PokePickerState = {
  searchQuery: '',
  selectedTypeIds: [],
  filterStats: baseFilterStats
}

export const usePokePickerStore = create<PokePickerState>(() => initialState)
