import { Button, Grid, Paper, TextField } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { AbilityModel } from '@shared/models/ability'
import { FilterStatsModel } from '@shared/models/filter-stats'
import { PokemonModel } from '@shared/models/pokemon'
import { TypeEfficaciesPowerModel } from '@shared/models/types/type-efficacies-power'
import { TypeBox } from '@root/components/roster/components/type-box'
import PokemonTable from './components/pokemon-table'
import { StatFilter } from './components/stat-filter'
import { baseFilterStats } from './constants/base-filter-stats'
import { resetFilter, selectType, setSearchQuery } from './store/actions'
import { usePokemon, useTypes } from './store/selectors'
import { usePokePickerStore } from './store/store'

interface Props {
  versionId: number
  availableAbilities: Array<AbilityModel>
  availablePokemon: Array<PokemonModel>
  availableTypes: Array<TypeEfficaciesPowerModel>
  mayAdd: boolean
  addPokemon: (pokemonId: number) => void
}

export const PokePicker: FC<Props> = ({
  versionId,
  availableAbilities,
  availablePokemon,
  availableTypes,
  mayAdd,
  addPokemon
}) => {
  const [stats, setStats] = useState<FilterStatsModel>(baseFilterStats)
  const [searchQuery] = usePokePickerStore((state) => [state.searchQuery])
  const pokemonList = usePokemon(
    versionId,
    availableAbilities,
    availablePokemon,
    availableTypes
  )
  const types = useTypes(availableTypes)

  useEffect(() => resetFilter, [])

  const clickReset = () => {
    resetFilter()
    setStats(baseFilterStats)
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item>
          <TextField
            variant="standard"
            label="Search name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Grid>
        <Grid item xs>
          <Grid
            container
            spacing={1}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <Button variant="contained" onClick={clickReset}>
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={0.5}>
            {types.map((type, index) => (
              <Grid key={index} item xs>
                <TypeBox
                  type={type.name}
                  active={type.selected}
                  amount={1}
                  onClick={() => selectType(type.id)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <StatFilter
            stats={stats}
            setStats={(_stats) => setStats({ ...stats, ..._stats })}
          />
        </Grid>
        <Grid item xs={12}>
          <PokemonTable
            pokemonList={pokemonList}
            mayAdd={mayAdd}
            addPokemon={addPokemon}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
