import { hasPresentKey } from 'ts-is-present'
import { TypeEfficaciesPowerModel } from '@shared/models/types/type-efficacies-power'
import { typeColors } from '@root/components/roster/constants/type-colors'
import { TypeViewModel } from '@root/pages/modify-roster/constants/type-view'

export const getTypes = (
  availableTypes: Array<TypeEfficaciesPowerModel>,
  selectedTypeIds: Array<number>
): Array<TypeViewModel> =>
  typeColors
    .map((typeColor) => ({
      ...typeColor,
      id: availableTypes.find(
        (availableType) => availableType.name === typeColor.type
      )?.id
    }))
    .filter(hasPresentKey('id'))
    .map((type) => ({
      id: type.id,
      name: type.type,
      selected: selectedTypeIds.some(
        (selectedTypeId) => selectedTypeId === type.id
      )
    }))
