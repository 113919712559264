import { MenuItem, TextField } from '@mui/material'
import { FC, useState } from 'react'
import { EditableRosterSlotViewModel } from '../../../models/editable-roster-slot-view'
import { setAbility, setItem } from '../../../store/actions'

interface Props {
  list: Array<{ id: number; name: string }>
  slot: EditableRosterSlotViewModel
  variant: 'ability' | 'item'
}

export const SlotSelect: FC<Props> = ({ list, slot, variant }) => {
  const [localValue, setLocalValue] = useState(
    variant === 'ability' ? slot.abilityId : slot.itemId
  )

  return (
    <TextField
      label={variant === 'ability' ? 'Ability' : 'Item'}
      value={localValue || ''}
      variant="standard"
      select
      fullWidth
      onChange={(e) => {
        const value = Number(e.target.value)
        setLocalValue(value)

        if (variant === 'ability') {
          setAbility(slot.slotOrder, value)
        } else {
          setItem(slot.slotOrder, value)
        }
      }}
    >
      {list.map((item, index) => (
        <MenuItem key={index} value={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </TextField>
  )
}
