import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { FC } from 'react'
import { MoveCategoryImage } from '@root/components/move-category-image'
import { TypeBox } from '@root/components/roster/components/type-box'
import { MoveViewModel } from '../models/move-view'

interface Props {
  title: string
  moves: Array<MoveViewModel>
  extraColumn?: string
  extraColumnValue?: (move: MoveViewModel) => string
  setMove: (id: number, typeId: number | undefined) => void
}

export const MoveTable: FC<Props> = ({
  title,
  moves,
  extraColumn,
  extraColumnValue,
  setMove
}) => {
  return moves.length ? (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell colSpan={5}>By {title}</TableCell>
          </TableRow>
          <TableRow>
            {extraColumn && <TableCell>{extraColumn}</TableCell>}
            <TableCell>Name</TableCell>
            <TableCell sx={{ width: 100 }}>Type</TableCell>
            <TableCell sx={{ width: 100 }}>Cat.</TableCell>
            <TableCell>Power</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {moves.map((move, index) => {
            return (
              <TableRow
                key={index}
                hover
                onClick={() => setMove(move.id, undefined)}
                sx={{ cursor: 'pointer' }}
              >
                {extraColumnValue && (
                  <TableCell size="small">{extraColumnValue(move)}</TableCell>
                )}
                <TableCell size="small">{move.name}</TableCell>
                <TableCell size="small">
                  <TypeBox type={move.type} active amount={1} />
                </TableCell>
                <TableCell size="small">
                  <MoveCategoryImage category={move.category} />
                </TableCell>
                <TableCell size="small">{move.power || '-'}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <></>
  )
}
