import { Button, LinearProgress } from '@mui/material'
import { FC } from 'react'
import { EmptySlot } from '@root/components/roster/empty-slot'
import { Roster } from '@root/components/roster/roster'
import { Slot } from '@root/components/roster/slot'
import { SlotValueLabel } from '@root/components/roster/slot-value-label'
import { Verdicts } from '@root/components/roster/verdicts'
import { RosterViewModel } from '../models/roster-view'

interface Props {
  loading: boolean
  roster: RosterViewModel
  modify: () => void
}

export const ViewableRoster: FC<Props> = ({ loading, roster, modify }) => (
  <Roster
    name={roster.name}
    topRightArea={
      <Button disabled={loading} onClick={modify}>
        Modify roster
      </Button>
    }
    slots={roster.slots.map((slot, slotIndex) =>
      slot ? (
        <Slot
          key={slotIndex}
          name={slot.name}
          imageUrl={slot.imageUrl}
          verdicts={
            <Verdicts
              verdicts={slot.verdicts}
              valueCreator={(category, value) => (
                <LinearProgress variant="determinate" value={value * 10} />
              )}
            />
          }
          role={<SlotValueLabel label="Role" value={slot.role} />}
          location={
            <SlotValueLabel label="Location" value={slot.location} dividePart />
          }
          ability={<SlotValueLabel label="Ability" value={slot.ability} />}
          item={<SlotValueLabel label="Item" value={slot.item} />}
          moves={slot.moves}
          primaryType={slot.primaryType}
          secondaryType={slot.secondaryType}
          stats={slot.stats}
        />
      ) : (
        <EmptySlot />
      )
    )}
    attackTypes={roster.attackTypes}
    weaknesses={roster.weaknesses}
  />
)
