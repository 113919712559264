import AddIcon from '@mui/icons-material/Add'
import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Toolbar,
  Typography
} from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { FC, Fragment, useEffect } from 'react'
import { Else, If, Then } from 'react-if'
import { Loader } from '@root/components/loader'
import { changeRoute } from '@root/utils/routes'
import { CreateRosterDialog } from './components/create-roster-dialog'
import { ViewableRoster } from './components/viewable-roster'
import {
  fetchRosters,
  loadModifyRoster,
  openCreateRosterDialog,
  resetRosterList
} from './store/actions'
import { useVersionRosters } from './store/selectors'
import { useRosterListStore } from './store/store'

export const RosterList: FC = () => {
  const viewState = useRosterListStore((state) => state.viewState)
  const versionRosters = useVersionRosters()

  useEffect(() => {
    fetchRosters()

    return resetRosterList
  }, [])

  const clickModify = (rosterId: string) => {
    loadModifyRoster()
    changeRoute(`/modify-roster/:id`, { id: rosterId })
  }

  return (
    <Box>
      <AppBar position="static" sx={{ bgcolor: blueGrey[700] }}>
        <Toolbar>
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box component={Typography} variant="h4" sx={{ p: 0 }}>
              Poké Builder
            </Box>
            <Box sx={{ position: 'absolute', right: 16 }}>
              <Button
                variant="outlined"
                color="inherit"
                startIcon={<AddIcon />}
                onClick={openCreateRosterDialog}
              >
                Create roster
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <If condition={viewState === 'fetching-rosters'}>
        <Then>
          <Loader />
        </Then>
        <Else>
          <Grid container spacing={2}>
            {versionRosters.map((version, index) => (
              <Fragment key={index}>
                <Grid item xs={12}>
                  <Box component={Typography} sx={{ my: 2 }}>
                    {version.name}
                  </Box>
                  <Divider />
                </Grid>
                {version.rosters.map((roster, rosterIndex) => (
                  <Grid key={rosterIndex} item xs={12}>
                    <ViewableRoster
                      loading={viewState === 'loading-modify-roster'}
                      roster={roster}
                      modify={() => clickModify(roster.id)}
                    />
                  </Grid>
                ))}
              </Fragment>
            ))}
          </Grid>
        </Else>
      </If>
      <CreateRosterDialog />
    </Box>
  )
}
