import { RosterDataModel } from '@shared/transport/http/collection/collection'
import { ClientEndpoint } from '@shared/transport/http/endpoints'

export const getRosters: ClientEndpoint<
  AbortSignal,
  'get',
  void,
  RosterDataModel
> = {
  method: 'get',
  dispatch: async () => ({
    availableVersions: [
      { id: 1, name: 'Red' },
      { id: 2, name: 'Blue' },
      { id: 3, name: 'Yellow' },
      { id: 4, name: 'Silver' },
      { id: 5, name: 'Gold' },
      { id: 21, name: 'Ultra Sun' },
      { id: 22, name: 'Ultra Moon' }
    ],
    versionRosters: [
      {
        name: 'Black 2, White 2',
        rosters: [
          {
            id: 'd627d9d5-7c9d-4a74-b394-aa7367eb6377',
            versionId: 16,
            name: 'test',
            slots: [
              {
                id: 68,
                speciesId: 68,
                slotOrder: 1,
                name: 'Machamp',
                form: undefined,
                verdicts: { earlyGame: 7, midGame: 9, endGame: 5 },
                role: 'Physical Sweeper',
                location: 'Part 4 (Route 17)',
                ability: 'Guts',
                item: 'Focus Band',
                moves: [
                  {
                    slotOrder: 1,
                    type: 'Fighting',
                    category: 'physical',
                    name: 'Cross Chop'
                  },
                  {
                    slotOrder: 2,
                    type: 'Normal',
                    category: 'status',
                    name: 'Swords Dance'
                  },
                  {
                    slotOrder: 3,
                    type: 'Fire',
                    category: 'physical',
                    name: 'Fire Punch'
                  },
                  {
                    slotOrder: 4,
                    type: 'Ground',
                    category: 'physical',
                    name: 'Earthquake'
                  }
                ],
                primaryType: 'Fighting',
                secondaryType: undefined,
                stats: {
                  hp: 90,
                  attack: 130,
                  defense: 80,
                  specialAttack: 65,
                  specialDefense: 85,
                  speed: 55
                }
              },
              {
                id: 6,
                speciesId: 6,
                versionId: 33,
                slotOrder: 3,
                name: 'Charizard',
                form: undefined,
                verdicts: { earlyGame: 7, midGame: 9, endGame: 5 },
                role: 'Physical Sweeper',
                location: 'Part 6 (Rock Tunnel)',
                ability: 'Blaze',
                item: 'Adrenaline Orb',
                moves: [
                  {
                    slotOrder: 1,
                    type: 'Normal',
                    category: 'status',
                    name: 'Swagger'
                  },
                  {
                    slotOrder: 2,
                    type: 'Fire',
                    category: 'special',
                    name: 'Flamethrower'
                  },
                  {
                    slotOrder: 3,
                    type: 'Dragon',
                    category: 'special',
                    name: 'Dragon Pulse'
                  },
                  {
                    slotOrder: 4,
                    type: 'Grass',
                    category: 'special',
                    name: 'Solar Beam'
                  }
                ],
                primaryType: 'Fire',
                secondaryType: 'Flying',
                stats: {
                  hp: 78,
                  attack: 84,
                  defense: 78,
                  specialAttack: 109,
                  specialDefense: 85,
                  speed: 100
                }
              }
            ],
            attackTypes: [
              { type: 'Fighting', amount: 1 },
              { type: 'Fire', amount: 2 },
              { type: 'Ground', amount: 1 },
              { type: 'Dragon', amount: 1 },
              { type: 'Grass', amount: 1 }
            ],
            weaknesses: [
              { type: 'Flying', amount: 1 },
              { type: 'Psychic', amount: 1 },
              { type: 'Fairy', amount: 1 },
              { type: 'Water', amount: 1 },
              { type: 'Electric', amount: 1 },
              { type: 'Rock', amount: 1 }
            ]
          }
        ]
      }
    ]
  })
}
