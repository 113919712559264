import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField
} from '@mui/material'
import { FC } from 'react'
import {
  closeRosterDialog,
  createRoster,
  selectVersionId,
  setRosterName
} from '../store/actions'
import { useRosterListStore } from '../store/store'

export const CreateRosterDialog: FC = () => {
  const [viewState, availableVersions, selectedVersionId, rosterName] =
    useRosterListStore((state) => [
      state.viewState,
      state.availableVersions,
      state.selectedVersionId,
      state.rosterName
    ])

  return (
    <Dialog
      maxWidth="xs"
      open={viewState === 'viewing-create-roster-dialog'}
      onClose={closeRosterDialog}
      sx={{ '.MuiPaper-root': { width: '100%' } }}
    >
      <DialogTitle>Create roster</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Version"
              autoFocus
              value={selectedVersionId}
              variant="standard"
              select
              fullWidth
              onChange={(e) => selectVersionId(Number(e.target.value))}
            >
              {availableVersions.map((version, index) => (
                <MenuItem key={index} value={version.id}>
                  {version.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Roster name"
              fullWidth
              variant="standard"
              value={rosterName}
              onChange={(e) => setRosterName(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeRosterDialog}>Cancel</Button>
        <Button onClick={createRoster}>Create</Button>
      </DialogActions>
    </Dialog>
  )
}
