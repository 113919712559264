import { Box, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { FC } from 'react'
import { Else, If, Then, When } from 'react-if'

interface Props {
  label: string
  value: string
  // TODO: Proper solution for location parts
  dividePart?: boolean
}

export const SlotValueLabel: FC<Props> = ({ label, value, dividePart }) => (
  <>
    <Box component={Typography} variant="caption" color={grey[600]}>
      {label}
      <When condition={dividePart}>
        <Box component="span" sx={{ fontSize: 12, ml: 0.5 }}>
          {value.slice(value.indexOf('(pt')) || '-'}
        </Box>
      </When>
    </Box>
    <If condition={dividePart}>
      <Then>
        <Box component={Typography}>
          {value.slice(0, value.indexOf('(pt')) || '-'}
        </Box>
      </Then>
      <Else>
        <Box component={Typography}>{value || '-'}</Box>
      </Else>
    </If>
  </>
)
