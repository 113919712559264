import { hasPresentKey } from 'ts-is-present'
import { TypeAmountModel } from '@shared/models/types/type-amount'
import { typeColors } from '@root/components/roster/constants/type-colors'
import { getImageUrl } from '@root/helpers/image-url'
import { getTotalStats } from '@root/helpers/total-stats'
import { useRosterListStore } from './store'
import { VersionRosterViewModel } from '../models/version-roster-view'

const getTypeAmountColor = (typeAmounts: Array<TypeAmountModel>) =>
  typeColors
    .map((typeColor) => ({
      type: typeColor.type,
      color: typeColor.color,
      amount: typeAmounts.find(
        (attackType) => attackType.type === typeColor.type
      )?.amount
    }))
    .filter(hasPresentKey('amount'))

export const useVersionRosters = (): Array<VersionRosterViewModel> => {
  const versionRosters = useRosterListStore((state) => state.versionRosters)

  return versionRosters.map((version) => ({
    ...version,
    rosters: version.rosters.map((roster) => {
      return {
        ...roster,
        slots: [...Array(6)]
          .map((_, index) =>
            roster.slots.find((_roster) => _roster.slotOrder === index + 1)
          )
          .map(
            (slot) =>
              slot && {
                ...slot,
                imageUrl: getImageUrl(
                  roster.versionId,
                  slot.speciesId,
                  slot.form
                ),
                moves: [...Array(4)].map((_, index) =>
                  slot.moves.find((move) => move.slotOrder === index + 1)
                ),
                stats: {
                  ...slot.stats,
                  total: getTotalStats(slot.stats)
                }
              }
          ),
        attackTypes: getTypeAmountColor(roster.attackTypes),
        weaknesses: getTypeAmountColor(roster.weaknesses)
      }
    })
  }))
}
