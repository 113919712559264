import { Box, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { FC, Fragment } from 'react'
import { RosterSlotVerdictsModel } from '@shared/models/roster/roster-slot-verdicts'

interface Props {
  verdicts: RosterSlotVerdictsModel
  valueCreator: (
    variant: keyof RosterSlotVerdictsModel,
    value: number
  ) => JSX.Element
}

const categories: Array<{
  label: string
  category: keyof RosterSlotVerdictsModel
}> = [
  { label: 'Early game', category: 'earlyGame' },
  { label: 'Mid game', category: 'midGame' },
  { label: 'End game', category: 'endGame' }
]

export const Verdicts: FC<Props> = ({ verdicts, valueCreator }) => (
  <>
    {categories.map(({ label, category }, index) => (
      <Fragment key={index}>
        <Box component={Typography} variant="caption" color={grey[600]}>
          {label}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            {valueCreator(category, verdicts[category])}
          </Box>
          <Box sx={{ minWidth: 15, textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              {verdicts[category]}
            </Typography>
          </Box>
        </Box>
      </Fragment>
    ))}
  </>
)
