import { Button, Grid, MenuItem, TextField } from '@mui/material'
import { FC, useState } from 'react'
import { TypeEfficaciesPowerModel } from '@shared/models/types/type-efficacies-power'
import { useModifyRosterStore } from '@root/pages/modify-roster/store/store'

interface Props {
  availableTypes: Array<TypeEfficaciesPowerModel>
  setMove: (id: number, typeId: number | undefined) => void
}

export const MoveSelector: FC<Props> = ({ availableTypes, setMove }) => {
  const moves = useModifyRosterStore((state) => state.moves)
  const [moveId, setMoveId] = useState(0)
  const [typeId, setTypeId] = useState<number | undefined>(undefined)

  return (
    <Grid container spacing={3} alignItems="flex-end">
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Move"
          value={moveId || ''}
          variant="standard"
          select
          onChange={(e) => {
            setMoveId(Number(e.target.value))
            setTypeId(undefined)
          }}
        >
          {moves.map((move, index) => (
            <MenuItem key={index} value={move.id}>
              {move.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Type"
          value={
            typeId || moves.find((move) => move.id === moveId)?.typeId || ''
          }
          variant="standard"
          select
          onChange={(e) => setTypeId(Number(e.target.value))}
        >
          {availableTypes.map((type, index) => (
            <MenuItem key={index} value={type.id}>
              {type.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={4}>
        <Button
          color="primary"
          onClick={() => setMove(moveId, typeId || undefined)}
        >
          Select
        </Button>
      </Grid>
    </Grid>
  )
}
