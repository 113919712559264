import create from 'zustand'
import { AbilityModel } from '@shared/models/ability'
import { ItemModel } from '@shared/models/item'
import { MoveModel } from '@shared/models/move'
import { PokemonModel } from '@shared/models/pokemon'
import { EditableRosterModel } from '@shared/models/roster/editable/editable-roster'
import { TypeEfficaciesPowerModel } from '@shared/models/types/type-efficacies-power'

export interface ModifyRosterState {
  viewState: 'viewing' | 'fetching' | 'loading-rosters'
  versionId: number
  roster: EditableRosterModel
  abilities: Array<AbilityModel>
  items: Array<ItemModel>
  moves: Array<MoveModel>
  pokemon: Array<PokemonModel>
  types: Array<TypeEfficaciesPowerModel>
  selectedSlotOrder: number | undefined
  selectedMove:
    | {
        slotOrder: number
        moveSlotOrder: number
      }
    | undefined
}

export const initialState: ModifyRosterState = {
  viewState: 'fetching',
  versionId: 0,
  roster: {
    id: '',
    name: '',
    slots: []
  },
  abilities: [],
  items: [],
  moves: [],
  pokemon: [],
  types: [],
  selectedSlotOrder: undefined,
  selectedMove: undefined
}

export const useModifyRosterStore = create<ModifyRosterState>(
  () => initialState
)
