import axios, { AxiosRequestConfig } from 'axios'
import { ClientEndpoint, RequestMethod } from '@shared/transport/http/endpoints'
import { getError } from '@shared/transport/http/errors'

const baseUrl = process.env.REACT_APP_SERVER_ADDRESS

export interface Options<P> extends AxiosRequestConfig {
  data?: P | FormData
}

export function getRoute(prototype: Function) {
  return Reflect.getMetadata('route', prototype)
}

export function getMethod(prototype: Function) {
  return Reflect.getMetadata('method', prototype)
}

export async function dispatch<P, R>(payload: P) {
  return request<P, R>(getRoute(this), getMethod(this), payload)
}

export const request = async <P, R>(
  route: string,
  method: RequestMethod,
  payload: P,
  options: Options<P> = {}
): Promise<R> => {
  try {
    const response = await axios.request<R>({
      method,
      url: `${baseUrl}${route}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token') || ''}`
      },
      data: method !== 'get' && { payload },
      params: method === 'get' && { payload: JSON.stringify(payload) },
      ...options
    })

    return response.data
    // FIXME
  } catch (e: any) {
    if (e?.response?.status) {
      throw new (getError(e.response.status))()
    }

    throw e
  }
}

export const getRouteUrl = <P extends {}>(
  endpoint: ClientEndpoint<any, 'get', P, any>,
  payload: P
) =>
  `${baseUrl}${Reflect.getMetadata('route', endpoint)}${
    payload && `?payload=${JSON.stringify(payload)}`
  }`
