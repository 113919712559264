import CheckIcon from '@mui/icons-material/Check'
import { Avatar, Grid } from '@mui/material'
import { green, grey } from '@mui/material/colors'
import { FC } from 'react'
import { selectSlot } from '../../../store/actions'

interface Props {
  slotOrder: number
  selectedSlotOrder: number | undefined
}

export const SelectCircle: FC<Props> = ({ slotOrder, selectedSlotOrder }) => (
  <Grid
    item
    xs={12}
    sx={{ display: 'flex', justifyContent: 'center', mb: 1.5 }}
  >
    <Avatar
      sx={{
        background: slotOrder === selectedSlotOrder ? green[500] : grey[400],
        transition: '200ms background',
        '&:hover': { background: green[500], cursor: 'pointer' }
      }}
      onClick={() => selectSlot(slotOrder)}
    >
      <CheckIcon />
    </Avatar>
  </Grid>
)
