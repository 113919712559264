import create from 'zustand'
import { MoveCategoryModel } from '@shared/models/move-category'

export interface MovePickerState {
  variant: 'regular' | 'specific'
  searchQuery: string
  category: MoveCategoryModel | undefined
  selectedTypeIds: Array<number>
}

export const initialState: MovePickerState = {
  variant: 'regular',
  searchQuery: '',
  category: undefined,
  selectedTypeIds: []
}

export const useMovePickerStore = create<MovePickerState>(() => initialState)
