import { Box } from '@mui/material'
import { FC } from 'react'

export const Shell: FC = ({ children }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: 1400,
        minHeight: '80vh',
        mx: 'auto',
        my: 2
      }}
    >
      <main>{children}</main>
    </Box>
  )
}
