import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { MoveCategoryModel } from '@shared/models/move-category'
import { MoveCategoryImage } from '@root/components/move-category-image'
import { typeColors } from '../constants/type-colors'

export const MoveBox: FC<{
  slotOrder: number
  move?: {
    slotOrder: number
    name: string
    type: string
    category: MoveCategoryModel
  }
  editMove?: (slotOrder: number) => void
}> = ({ slotOrder, move, editMove }) => (
  <Box
    sx={{
      cursor: editMove ? 'pointer' : '',
      overflow: 'hidden',
      display: 'flex',
      color: move ? 'white' : '',
      borderRadius: 8,
      textShadow: move ? '1px 1px 2px rgb(0 0 0 / 70%)' : '',
      background:
        (move && typeColors.find((_type) => _type.type === move.type)?.color) ||
        '',
      border: '2px solid rgba(0,0,0,.2)'
    }}
    onClick={() => editMove && editMove(slotOrder)}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#eee',
        height: 1,
        px: 0.5,
        mr: 0.5
      }}
    >
      {move && <MoveCategoryImage category={move.category} />}
    </Box>
    <Typography>{move ? move.name : 'Empty move'}</Typography>
  </Box>
)
