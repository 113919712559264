import { useMemo } from 'react'
import { isPresent } from 'ts-is-present'
import { AbilityModel } from '@shared/models/ability'
import { PokemonModel } from '@shared/models/pokemon'
import { TypeEfficaciesPowerModel } from '@shared/models/types/type-efficacies-power'
import { getSmallImageUrl } from '@root/helpers/image-url'
import { getTotalStats } from '@root/helpers/total-stats'
import { TypeViewModel } from '@root/pages/modify-roster/constants/type-view'
import { getTypes } from '@root/pages/modify-roster/helpers/get-types'
import { usePokePickerStore } from './store'
import { PokemonViewModel } from '../models/pokemon-view'

export const usePokemon = (
  versionId: number,
  availableAbilities: Array<AbilityModel>,
  availablePokemon: Array<PokemonModel>,
  availableTypes: Array<TypeEfficaciesPowerModel>
): Array<PokemonViewModel> => {
  const [searchQuery, filterStats, selectedTypeIds] = usePokePickerStore(
    (state) => [state.searchQuery, state.filterStats, state.selectedTypeIds]
  )

  return useMemo(
    () =>
      availablePokemon
        .filter(
          (pokemon) =>
            pokemon.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
            getTotalStats(pokemon.stats) <= filterStats.total &&
            Object.keys(pokemon.stats).every(
              (stat) => pokemon.stats[stat] >= filterStats[stat]
            ) &&
            (!selectedTypeIds.length ||
              selectedTypeIds.some(
                (typeId) =>
                  pokemon.primaryTypeId === typeId ||
                  pokemon.secondaryTypeId === typeId
              ))
        )
        .map((pokemon) => {
          return {
            id: pokemon.id,
            speciesId: pokemon.speciesId,
            imageUrl: getSmallImageUrl(
              versionId,
              pokemon.speciesId,
              pokemon.form
            ),
            regularPokedex: pokemon.regularPokedex,
            name: pokemon.name,
            types: [pokemon.primaryTypeId, pokemon.secondaryTypeId]
              .map(
                (typeId) =>
                  availableTypes.find((type) => type.id === typeId)?.name
              )
              .filter(isPresent),
            abilities: pokemon.abilityIds
              .map(
                (abilityId) =>
                  availableAbilities.find((ability) => ability.id === abilityId)
                    ?.name
              )
              .filter(isPresent),
            stats: [
              pokemon.stats.hp,
              pokemon.stats.attack,
              pokemon.stats.defense,
              pokemon.stats.specialAttack,
              pokemon.stats.specialDefense,
              pokemon.stats.speed
            ]
          }
        })
        .sort((a, b) => {
          if (a.speciesId === b.speciesId) {
            return a.id - b.id
          }

          return a.speciesId - b.speciesId
        }),
    [
      versionId,
      availableAbilities,
      availablePokemon,
      availableTypes,
      searchQuery,
      filterStats,
      selectedTypeIds
    ]
  )
}

export const useTypes = (
  availableTypes: Array<TypeEfficaciesPowerModel>
): Array<TypeViewModel> => {
  const selectedTypeIds = usePokePickerStore((state) => state.selectedTypeIds)

  return getTypes(availableTypes, selectedTypeIds)
}
