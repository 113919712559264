import debounce from '@mui/utils/debounce'
import { EditableRosterSlotModel } from '@shared/models/roster/editable/editable-roster-slot'
import { RosterSlotVerdictsModel } from '@shared/models/roster/roster-slot-verdicts'
import { EditableRosterDataModel } from '@shared/transport/http/collection/collection'
import { endpoints } from '@root/transport/http/endpoints'
import { initialState, useModifyRosterStore } from './store'

const { getState, setState } = useModifyRosterStore

const getRosterId = () => getState().roster.id

const setSlotData = (
  slotOrder: number,
  getData: (slot: EditableRosterSlotModel) => Partial<EditableRosterSlotModel>
) => {
  const { roster } = getState()

  setState({
    roster: {
      ...roster,
      slots: roster.slots.map((slot) =>
        slot.slotOrder === slotOrder ? { ...slot, ...getData(slot) } : slot
      )
    }
  })
}

const saveRole = debounce(
  (slotOrder: number, role: string) =>
    endpoints.slots.setRole.dispatch({
      rosterId: getRosterId(),
      slotOrder,
      role
    }),
  500
)

const saveLocation = debounce(
  (slotOrder: number, location: string) =>
    endpoints.slots.setLocation.dispatch({
      rosterId: getRosterId(),
      slotOrder,
      location
    }),
  500
)

export const reset = () => setState({ ...initialState })
export const setEditableRosterData = (rosterData: EditableRosterDataModel) => {
  const { versionId, roster, abilities, items, moves, pokemon, types } =
    rosterData

  setState({
    viewState: 'viewing',
    versionId,
    roster,
    abilities,
    items,
    moves,
    pokemon,
    types
  })
}

export const fetchEditableRosterData = async (rosterId: string) => {
  if (getState().viewState === 'fetching') {
    setEditableRosterData(
      await endpoints.getEditableRosterData.dispatch(rosterId)
    )
  }
}

export const loadRosters = () => setState({ viewState: 'loading-rosters' })

export const selectSlot = (slotOrder: number) =>
  setState({
    selectedSlotOrder:
      getState().selectedSlotOrder === slotOrder ? undefined : slotOrder
  })

export const deselectMove = () => setState({ selectedMove: undefined })
export const selectMove = (slotOrder: number, moveSlotOrder: number) =>
  setState({ selectedMove: { slotOrder, moveSlotOrder } })

export const setMove = async (id: number, typeId: number | undefined) => {
  const { roster, selectedMove } = getState()

  if (selectedMove) {
    await endpoints.slots.setMove.dispatch({
      rosterId: roster.id,
      slotOrder: selectedMove.slotOrder,
      moveSlotOrder: selectedMove.moveSlotOrder,
      moveId: id,
      typeId
    })

    setState({
      roster: {
        ...roster,
        slots: roster.slots.map((slot) =>
          slot.slotOrder === selectedMove.slotOrder
            ? {
                ...slot,
                moves: slot.moves.find(
                  (move) => move.slotOrder === selectedMove.moveSlotOrder
                )
                  ? slot.moves.map((move) =>
                      move.slotOrder === selectedMove.moveSlotOrder
                        ? { ...move, id, typeId }
                        : move
                    )
                  : [
                      ...slot.moves,
                      {
                        slotOrder: selectedMove.moveSlotOrder,
                        id,
                        typeId
                      }
                    ]
              }
            : slot
        )
      },
      selectedMove: undefined
    })
  }
}

export const setPokemon = (slotOrder: number, pokemonId: number) => {
  const { roster } = getState()

  endpoints.slots.setPokemon.dispatch({
    rosterId: getRosterId(),
    slotOrder,
    pokemonId
  })

  if (roster.slots.find((slot) => slot.slotOrder === slotOrder)) {
    setSlotData(slotOrder, () => ({ id: pokemonId }))
  } else {
    setState({
      roster: {
        ...roster,
        slots: [
          ...roster.slots,
          {
            id: pokemonId,
            slotOrder,
            verdicts: { earlyGame: 0, midGame: 0, endGame: 0 },
            role: '',
            location: '',
            abilityId: 0,
            itemId: 0,
            moves: []
          }
        ]
      }
    })
  }
}

export const setVerdict = (
  slotOrder: number,
  category: keyof RosterSlotVerdictsModel,
  value: number
) => {
  endpoints.slots.setVerdict.dispatch({
    rosterId: getRosterId(),
    slotOrder,
    category,
    value
  })
  setSlotData(slotOrder, (slot) => ({
    verdicts: { ...slot.verdicts, [category]: value }
  }))
}

export const setRole = (slotOrder: number, role: string) => {
  saveRole(slotOrder, role)
  setSlotData(slotOrder, () => ({ role }))
}

export const setLocation = (slotOrder: number, location: string) => {
  saveLocation(slotOrder, location)
  setSlotData(slotOrder, () => ({ location }))
}

export const setAbility = (slotOrder: number, abilityId: number) => {
  endpoints.slots.setAbility.dispatch({
    rosterId: getRosterId(),
    slotOrder,
    abilityId
  })
  setSlotData(slotOrder, () => ({ abilityId }))
}

export const setItem = (slotOrder: number, itemId: number) => {
  endpoints.slots.setItem.dispatch({
    rosterId: getRosterId(),
    slotOrder,
    itemId
  })
  setSlotData(slotOrder, () => ({ itemId }))
}

export const removeSlot = () => {
  const { roster, selectedSlotOrder } = getState()

  if (selectedSlotOrder) {
    endpoints.slots.remove.dispatch({
      rosterId: roster.id,
      slotOrder: selectedSlotOrder
    })

    setState({
      roster: {
        ...roster,
        slots: roster.slots.filter(
          (slot) => slot.slotOrder !== selectedSlotOrder
        )
      },
      selectedSlotOrder: undefined
    })
  }
}
