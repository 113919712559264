import { FilterStatsModel } from '@shared/models/filter-stats'

export const baseFilterStats: FilterStatsModel = {
  hp: 0,
  attack: 0,
  defense: 0,
  specialAttack: 0,
  specialDefense: 0,
  speed: 0,
  total: 580
}
