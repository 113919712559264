const getFormSuffix = (form: string | undefined): string => {
  switch (form) {
    case 'alola':
      return '-a'
    case 'galar':
      return '-g'
    case 'heat':
    case 'wash':
    case 'frost':
    case 'fan':
    case 'mow':
      return `-${form.slice(0, 1)}`
  }

  return ''
}

export const getImageUrl = (
  versionId: number,
  speciesId: number,
  form: string | undefined
): string => {
  const speciesIdString = `${speciesId
    .toString()
    .padStart(3, '0')}${getFormSuffix(form)}`

  switch (versionId) {
    case 1:
    case 2:
      return `https://www.serebii.net/pokearth/sprites/rb/${speciesIdString}.png`
    case 3:
      return `https://www.serebii.net/pokearth/sprites/yellow/${speciesIdString}.png`
    case 4:
      return `https://www.serebii.net/pokearth/sprites/gold/${speciesIdString}.png`
    case 5:
      return `https://www.serebii.net/pokearth/sprites/silver/${speciesIdString}.png`
    case 6:
      return `https://www.serebii.net/pokearth/sprites/crystal/${speciesIdString}.png`
    case 7:
    case 8:
      return `https://www.serebii.net/pokemon_advance/${speciesIdString}.gif`
    case 9:
      return `https://www.serebii.net/emerald/pokemon/${speciesIdString}.gif`
    case 10:
    case 11:
      return `https://www.serebii.net/red_green/${speciesIdString}.gif`
    case 12:
    case 13:
    case 14:
      return `https://www.serebii.net/pokearth/sprites/dp/${speciesIdString}.png`
    case 15:
    case 16:
      return `https://www.serebii.net/pokearth/sprites/hgss/${speciesIdString}.png`
    case 17:
    case 18:
    case 21:
    case 22:
      return `https://www.serebii.net/blackwhite/pokemon/${speciesIdString}.png`
    case 23:
    case 24:
    case 25:
    case 26:
      return `https://www.serebii.net/xy/pokemon/${speciesIdString}.png`
    case 27:
    case 28:
    case 29:
    case 30:
    case 31:
    case 32:
      return `https://www.serebii.net/sunmoon/pokemon/${speciesIdString}.png`
    case 33:
    case 34:
    case 35:
    case 36:
      return `https://www.serebii.net/swordshield/pokemon/${speciesIdString}.png`
  }

  throw new Error(`Could not find imageUrl for version ${versionId}`)
}

export const getSmallImageUrl = (
  versionId: number,
  speciesId: number,
  form: string | undefined
): string => {
  const speciesIdString = `${speciesId
    .toString()
    .padStart(3, '0')}${getFormSuffix(form)}`

  switch (versionId) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
    case 17:
    case 18:
    case 19:
    case 20:
    case 21:
    case 22:
    case 23:
    case 24:
    case 25:
    case 26:
      return `https://www.serebii.net/pokedex-xy/icon/${speciesIdString}.png`
    case 27:
    case 28:
    case 29:
    case 30:
    case 31:
    case 32:
      return `https://serebii.net/pokedex-sm/icon/${speciesIdString}.png`
    case 33:
    case 34:
    case 35:
    case 36:
      return `https://www.serebii.net/pokedex-swsh/icon/${speciesIdString}.png`
  }

  throw new Error(`Could not find imageUrl for version ${versionId}`)
}
