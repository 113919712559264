"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWeaknesses = void 0;
const getWeakTypes = (typeEfficacies, primaryTypeId, secondaryTypeId) => [primaryTypeId, secondaryTypeId]
    .filter(Boolean)
    .reduce((weakTypes, typeId) => typeEfficacies
    .filter((typeEfficacy) => typeEfficacy.id === typeId)
    .map((type) => type.efficacies.map((efficacy) => ({
    typeId: type.id,
    efficacyId: efficacy.typeId,
    power: efficacy.power
})))
    .flat()
    .reduce((weakTypes, typeEfficacy) => weakTypes.find((weakType) => weakType.efficacyId === typeEfficacy.efficacyId)
    ? weakTypes.map((weakType) => weakType.efficacyId === typeEfficacy.efficacyId
        ? {
            ...weakType,
            power: weakType.power + typeEfficacy.power
        }
        : weakType)
    : [...weakTypes, { ...typeEfficacy }], weakTypes), [])
    .filter((weakType) => weakType.power > 0)
    .map((weakType) => weakType.efficacyId);
const getWeaknesses = (slotPokemonTypes, typeEfficacies) => slotPokemonTypes
    .reduce((weaknesses, slot) => getWeakTypes(typeEfficacies, slot.primaryTypeId, slot.secondaryTypeId)
    .filter(Boolean)
    .reduce((typeWeaknesses, typeId) => typeWeaknesses.map((weakness) => weakness.id === typeId
    ? { ...weakness, amount: weakness.amount + 1 }
    : weakness), weaknesses), typeEfficacies.map((type) => ({
    id: type.id,
    name: type.name,
    amount: 0
})))
    .map((weakType) => ({ type: weakType.name, amount: weakType.amount }));
exports.getWeaknesses = getWeaknesses;
