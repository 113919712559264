import {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lime,
  pink,
  purple,
  red
} from '@mui/material/colors'

export const typeColors: Array<{ type: string; color: string }> = [
  { type: 'Normal', color: grey.A400 },
  { type: 'Fire', color: deepOrange[500] },
  { type: 'Water', color: blue[400] },
  { type: 'Electric', color: amber[500] },
  { type: 'Grass', color: green[500] },
  { type: 'Ice', color: cyan[200] },
  { type: 'Fighting', color: red[900] },
  { type: 'Poison', color: purple[400] },
  { type: 'Ground', color: amber[200] },
  { type: 'Flying', color: indigo.A100 },
  { type: 'Psychic', color: pink[400] },
  { type: 'Bug', color: lime[600] },
  { type: 'Rock', color: lime[900] },
  { type: 'Ghost', color: indigo['400'] },
  { type: 'Dragon', color: deepPurple[300] },
  { type: 'Dark', color: brown[600] },
  { type: 'Steel', color: blueGrey.A200 },
  { type: 'Fairy', color: pink[100] }
]
