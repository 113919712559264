import { TypeEfficaciesPowerModel } from '@shared/models/types/type-efficacies-power'
import { TypeViewModel } from '@root/pages/modify-roster/constants/type-view'
import { getTypes } from '@root/pages/modify-roster/helpers/get-types'
import { useAvailableMoves } from '@root/pages/modify-roster/store/selectors'
import { useMovePickerStore } from './store'
import { MoveListModel } from '../models/move-list'

const findType = (
  availableTypes: Array<TypeEfficaciesPowerModel>,
  typeId: number
) => {
  const type = availableTypes.find((_type) => _type.id === typeId)

  if (!type) {
    throw new Error()
  }

  return type
}

export const useTypes = (
  availableTypes: Array<TypeEfficaciesPowerModel>
): Array<TypeViewModel> => {
  const selectedTypeIds = useMovePickerStore((state) => state.selectedTypeIds)

  return getTypes(availableTypes, selectedTypeIds)
}

export const useMoves = (
  availableTypes: Array<TypeEfficaciesPowerModel>
): MoveListModel => {
  const [searchQuery, category, selectedTypeIds] = useMovePickerStore(
    (state) => [state.searchQuery, state.category, state.selectedTypeIds]
  )
  const availableMoves = useAvailableMoves()

  const moves = availableMoves
    .filter(
      (move) =>
        move.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (!category || move.category === category) &&
        (!selectedTypeIds.length ||
          selectedTypeIds.some((typeId) => typeId === move.typeId))
    )
    .map((move) => ({
      ...move,
      type: findType(availableTypes, move.typeId).name
    }))

  return {
    byLevel: moves
      .filter((move) => move.method.variant === 'level-up')
      .sort((a, b) => {
        if (
          a.method.variant === 'level-up' &&
          b.method.variant === 'level-up'
        ) {
          return a.method.level - b.method.level
        }

        return 0
      }),
    byTechnical: moves
      .filter((move) => move.method.variant === 'technical')
      .sort((a, b) => {
        if (
          a.method.variant === 'technical' &&
          b.method.variant === 'technical'
        ) {
          return a.method.id > b.method.id ? 1 : -1
        }

        return 0
      }),
    byTutor: moves.filter((move) => move.method.variant === 'tutor'),
    byEgg: moves.filter((move) => move.method.variant === 'egg')
  }
}
