import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tab,
  Tabs
} from '@mui/material'
import { FC, useEffect } from 'react'
import { Else, If, Then } from 'react-if'
import { TypeEfficaciesPowerModel } from '@shared/models/types/type-efficacies-power'
import { MoveFilter } from './components/move-filter'
import { MoveList } from './components/move-list'
import { MoveSelector } from './components/move-selector'
import { resetFilter, setVariant } from './store/actions'
import { useMoves } from './store/selectors'
import { useMovePickerStore } from './store/store'

interface Props {
  open: boolean
  availableTypes: Array<TypeEfficaciesPowerModel>
  close: () => void
  setMove: (id: number, typeId: number | undefined) => void
}

export const MovePicker: FC<Props> = ({
  open,
  availableTypes,
  close,
  setMove
}) => {
  const variant = useMovePickerStore((state) => state.variant)
  const moves = useMoves(availableTypes)

  useEffect(
    () => () => {
      open && resetFilter()
    },
    [open]
  )

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={close}
      sx={{ '.MuiPaper-root': { height: '100%', width: '100%' } }}
    >
      <DialogTitle>
        Move Picker
        <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ overflowY: 'scroll' }}>
        <Tabs
          variant="fullWidth"
          value={variant}
          onChange={(e, _variant) => setVariant(_variant)}
          style={{ marginBottom: 16 }}
        >
          <Tab label="Regular moves" value="regular" />
          <Tab label="Specific move" value="specific" />
        </Tabs>
        <If condition={variant === 'regular'}>
          <Then>
            <Grid container spacing={2}>
              <MoveFilter availableTypes={availableTypes} />
              <Grid item xs={12}>
                <MoveList open={open} moves={moves} setMove={setMove} />
              </Grid>
            </Grid>
          </Then>
          <Else>
            <MoveSelector availableTypes={availableTypes} setMove={setMove} />
          </Else>
        </If>
      </DialogContent>
    </Dialog>
  )
}
