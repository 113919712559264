import { FC } from 'react'
import { Slot } from '@root/components/roster/slot'
import { SlotValueLabel } from './slot-value-label'

interface Props {
  selectedSlotOrder?: number | undefined
  sidebarArea?: JSX.Element
}

export const EmptySlot: FC<Props> = ({ selectedSlotOrder, sidebarArea }) => (
  <Slot
    name="Empty slot"
    selectedSlotOrder={selectedSlotOrder}
    role={<SlotValueLabel label="Role" value="-" />}
    location={<SlotValueLabel label="Location" value="-" />}
    ability={<SlotValueLabel label="Ability" value="-" />}
    item={<SlotValueLabel label="Item" value="-" />}
    moves={[...new Array(4)]}
    sidebarArea={sidebarArea}
    stats={{
      hp: '-',
      attack: '-',
      defense: '-',
      specialAttack: '-',
      specialDefense: '-',
      speed: '-',
      total: '-'
    }}
  />
)
