import { Box, Card, Grid, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { FC, memo } from 'react'
import { ViewableRosterSlotMoveModel } from '@shared/models/roster/viewable/viewable-roster-slot-move'
import { MoveBox } from './components/move-box'
import { SlotSidebar } from './components/slot-sidebar'

interface Props {
  name: string
  selectedSlotOrder?: number | undefined
  imageUrl?: string
  verdicts?: JSX.Element
  role: JSX.Element
  location: JSX.Element
  ability: JSX.Element
  item: JSX.Element
  moves: Array<ViewableRosterSlotMoveModel | undefined>
  editMove?: (slotOrder: number) => void
  primaryType?: string
  secondaryType?: string
  sidebarArea?: JSX.Element
  stats: {
    hp: string | number
    attack: string | number
    defense: string | number
    specialAttack: string | number
    specialDefense: string | number
    speed: string | number
    total: string | number
  }
}

export const Slot: FC<Props> = memo(
  ({
    name,
    imageUrl,
    verdicts,
    role,
    location,
    ability,
    item,
    moves,
    editMove,
    primaryType,
    secondaryType,
    sidebarArea,
    stats
  }) => (
    <Box sx={{ display: 'flex' }}>
      <Card sx={{ p: 2, background: grey[100] }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography variant="h6">{name}</Typography>
            <Box width={125} height={125}>
              {imageUrl && (
                <Box
                  component="img"
                  alt=""
                  src={imageUrl}
                  sx={{ display: 'block', maxHeight: '100%' }}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            {verdicts}
          </Grid>
          <Grid item xs={6}>
            {role}
          </Grid>
          <Grid item xs={6}>
            {location}
          </Grid>
          <Grid item xs={6}>
            {ability}
          </Grid>
          <Grid item xs={6}>
            {item}
          </Grid>
          {moves.map((move, index) => (
            <Grid key={index} item xs={6}>
              <MoveBox slotOrder={index + 1} move={move} editMove={editMove} />
            </Grid>
          ))}
        </Grid>
      </Card>
      <SlotSidebar
        primaryType={primaryType}
        secondaryType={secondaryType}
        sidebarArea={sidebarArea}
        stats={stats}
      />
    </Box>
  ),
  (prevProps, nextProps) =>
    prevProps.imageUrl === nextProps.imageUrl &&
    prevProps.selectedSlotOrder === nextProps.selectedSlotOrder &&
    prevProps.moves.length === nextProps.moves.length &&
    prevProps.moves.every(
      (move, index) => move?.name === nextProps.moves[index]?.name
    )
)
