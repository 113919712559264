import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Fab, Grid } from '@mui/material'
import { FC, useEffect } from 'react'
import { Else, If, Then } from 'react-if'
import { useParams } from 'react-router'
import shallow from 'zustand/shallow'
import { Loader } from '@root/components/loader'
import { EditableRoster } from './components/editable-roster/editable-roster'
import { MovePicker } from './components/move-picker/move-picker'
import { PokePicker } from './components/poke-picker/poke-picker'
import {
  deselectMove,
  fetchEditableRosterData,
  removeSlot,
  reset,
  selectMove,
  setMove,
  setPokemon
} from './store/actions'
import { useModifyRosterStore } from './store/store'

export const ModifyRoster: FC = () => {
  const [
    viewState,
    versionId,
    abilities,
    items,
    pokemon,
    types,
    selectedSlotOrder,
    selectedMove
  ] = useModifyRosterStore(
    (state) => [
      state.viewState,
      state.versionId,
      state.abilities,
      state.items,
      state.pokemon,
      state.types,
      state.selectedSlotOrder,
      state.selectedMove
    ],
    shallow
  )
  const params = useParams<{ id: string }>()

  useEffect(() => {
    fetchEditableRosterData(params.id)

    return reset
  }, [params.id])

  return (
    <If condition={viewState === 'fetching'}>
      <Then>
        <Loader />
      </Then>
      <Else>
        <Grid container spacing={3} sx={{ mb: 11 }}>
          <Grid item xs={12}>
            <PokePicker
              versionId={versionId}
              availableAbilities={abilities}
              availablePokemon={pokemon}
              availableTypes={types}
              mayAdd={selectedSlotOrder !== undefined}
              addPokemon={(pokemonId) =>
                selectedSlotOrder && setPokemon(selectedSlotOrder, pokemonId)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <EditableRoster
              loading={viewState === 'loading-rosters'}
              items={items}
              selectedSlotOrder={selectedSlotOrder}
              selectMove={selectMove}
            />
          </Grid>
        </Grid>
        <MovePicker
          open={Boolean(selectedMove)}
          availableTypes={types}
          close={deselectMove}
          setMove={(id, typeId) => setMove(id, typeId)}
        />
        <Box sx={{ position: 'fixed', right: 16, bottom: 16 }}>
          <Fab
            color="primary"
            disabled={!selectedSlotOrder}
            onClick={removeSlot}
          >
            <DeleteIcon />
          </Fab>
        </Box>
      </Else>
    </If>
  )
}
