import { hasPresentKey, isDefined } from 'ts-is-present'
import { getAttackTypes } from '@shared/helpers/attack-types'
import { getWeaknesses } from '@shared/helpers/weaknesses'
import { MoveModel } from '@shared/models/move'
import { TypeAmountModel } from '@shared/models/types/type-amount'
import { TypeEfficaciesPowerModel } from '@shared/models/types/type-efficacies-power'
import { typeColors } from '@root/components/roster/constants/type-colors'
import { getImageUrl } from '@root/helpers/image-url'
import { getTotalStats } from '@root/helpers/total-stats'
import { useModifyRosterStore } from './store'
import { EditableMoveViewModel } from '../models/editable-move-view'
import { EditableRosterSlotViewModel } from '../models/editable-roster-slot-view'
import { EditableRosterViewModel } from '../models/editable-roster-view'

const getTypeAmountColor = (typeAmounts: Array<TypeAmountModel>) =>
  typeColors
    .map((typeColor) => ({
      type: typeColor.type,
      color: typeColor.color,
      amount: typeAmounts.find(
        (attackType) => attackType.type === typeColor.type
      )?.amount
    }))
    .filter(hasPresentKey('amount'))

const getMove = (
  slotOrder: number,
  moveIdentifier: { id: number; typeId: number | undefined } | undefined,
  moves: Array<MoveModel>,
  types: Array<TypeEfficaciesPowerModel>
) => {
  const move = moves.find((_move) => moveIdentifier?.id === _move.id)

  if (move) {
    const type = types.find(
      (_type) => _type.id === (moveIdentifier?.typeId || move.typeId)
    )

    if (type) {
      return {
        slotOrder,
        name: move.name,
        typeId: type.id,
        type: type.name,
        category: move.category
      }
    }
  }
}

export const useRoster = (): EditableRosterViewModel => {
  const [
    versionId,
    roster,
    abilities,
    moves,
    availablePokemon,
    types,
    selectedSlotOrder
  ] = useModifyRosterStore((state) => [
    state.versionId,
    state.roster,
    state.abilities,
    state.moves,
    state.pokemon,
    state.types,
    state.selectedSlotOrder
  ])

  const slotPokemonTypes = roster.slots
    .map((slot) => {
      const pokemon = availablePokemon.find(
        (_pokemon) => _pokemon.id === slot?.id
      )
      const primaryType = types.find(
        (type) => type.id === pokemon?.primaryTypeId
      )
      const secondaryType = types.find(
        (type) => type.id === pokemon?.secondaryTypeId
      )

      return {
        ...slot,
        moves: [...new Array(4)].map((_, index) =>
          getMove(
            index + 1,
            slot.moves.find(
              (moveIdentifier) => moveIdentifier.slotOrder === index + 1
            ),
            moves,
            types
          )
        ),
        pokemon,
        primaryType,
        secondaryType
      }
    })
    .filter(hasPresentKey('pokemon'))
    .filter(hasPresentKey('primaryType'))

  const slots: Array<EditableRosterSlotViewModel | undefined> = [...Array(6)]
    .map((_, index) =>
      slotPokemonTypes.find((_roster) => _roster.slotOrder === index + 1)
    )
    .map((slot) => {
      return (
        slot && {
          ...slot,
          selected: selectedSlotOrder === slot.slotOrder,
          name: slot.pokemon.name,
          primaryType: slot.primaryType.name,
          secondaryType: slot.secondaryType?.name,
          imageUrl: getImageUrl(
            versionId,
            slot.pokemon.speciesId,
            slot.pokemon.form
          ),
          stats: {
            ...slot.pokemon.stats,
            total: getTotalStats(slot.pokemon.stats)
          },
          abilities: abilities.filter((ability) =>
            slot.pokemon.abilityIds.find(
              (abilityId) => abilityId === ability.id
            )
          )
        }
      )
    })

  return {
    ...roster,
    slots,
    attackTypes: getTypeAmountColor(
      getAttackTypes(
        slotPokemonTypes.map((slot) => slot.moves.filter(isDefined)),
        types
      )
    ),
    weaknesses: getTypeAmountColor(
      getWeaknesses(
        slotPokemonTypes.map((slot) => ({
          primaryTypeId: slot.primaryType.id,
          secondaryTypeId: slot.secondaryType?.id
        })),
        types
      )
    )
  }
}

export const useAvailableMoves = (): Array<EditableMoveViewModel> => {
  const [selectedMove, moves, pokemon, roster] = useModifyRosterStore(
    (state) => [state.selectedMove, state.moves, state.pokemon, state.roster]
  )

  const selectedSlot = roster.slots.find(
    (slot) => slot.slotOrder === selectedMove?.slotOrder
  )
  const selectedPokemon = pokemon.find(
    (_pokemon) => _pokemon.id === selectedSlot?.id
  )

  if (selectedPokemon) {
    return moves
      .map((move) => ({
        move,
        pokemonMove: selectedPokemon.moves.find(
          (_pokemonMove) => _pokemonMove.id === move.id
        )
      }))
      .filter(hasPresentKey('pokemonMove'))
      .map(({ move, pokemonMove }) => ({ ...move, method: pokemonMove.method }))
  }

  return []
}
