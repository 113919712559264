import { FC } from 'react'
import { MoveCategoryModel } from '@shared/models/move-category'

const getCategoryImage = (category: MoveCategoryModel) => {
  switch (category) {
    case 'physical':
      return 'https://img.pokemondb.net/images/icons/move-physical.png'
    case 'special':
      return 'https://img.pokemondb.net/images/icons/move-special.png'
    default:
      return 'https://img.pokemondb.net/images/icons/move-status.png'
  }
}

interface Props {
  category: MoveCategoryModel
}

export const MoveCategoryImage: FC<Props> = ({ category }) => (
  <img alt="" src={getCategoryImage(category)} width="24" height="24" />
)
