import { Button } from '@mui/material'
import { FC } from 'react'
import { ItemModel } from '@shared/models/item'
import { EmptySlot } from '@root/components/roster/empty-slot'
import { Roster } from '@root/components/roster/roster'
import { Slot } from '@root/components/roster/slot'
import { changeRoute } from '@root/utils/routes'
import { EditableVerdicts } from './components/editable-verdicts'
import { SelectCircle } from './components/select-circle'
import { SlotField } from './components/slot-field'
import { SlotSelect } from './components/slot-select'
import { loadRosters, selectMove } from '../../store/actions'
import { useRoster } from '../../store/selectors'

interface Props {
  loading: boolean
  selectedSlotOrder: number | undefined
  selectMove: (slotOrder: number, moveSlotOrder: number) => void
  items: Array<ItemModel>
}

export const EditableRoster: FC<Props> = ({
  loading,
  selectedSlotOrder,
  items
}) => {
  const roster = useRoster()

  const clickBack = () => {
    loadRosters()
    changeRoute('/')
  }

  return (
    <Roster
      name={roster.name}
      topRightArea={
        <Button disabled={loading} onClick={clickBack}>
          Back
        </Button>
      }
      slots={roster.slots.map((slot, slotIndex) =>
        slot ? (
          <Slot
            key={slotIndex}
            name={slot.name}
            selectedSlotOrder={selectedSlotOrder}
            imageUrl={slot.imageUrl}
            verdicts={<EditableVerdicts slot={slot} />}
            role={<SlotField slot={slot} variant="role" />}
            location={<SlotField slot={slot} variant="location" />}
            ability={
              <SlotSelect list={slot.abilities} slot={slot} variant="ability" />
            }
            item={<SlotSelect list={items} slot={slot} variant="item" />}
            moves={slot.moves}
            editMove={(moveSlotOrder) =>
              selectMove(slot.slotOrder, moveSlotOrder)
            }
            primaryType={slot.primaryType}
            secondaryType={slot.secondaryType}
            sidebarArea={
              <SelectCircle
                slotOrder={slot.slotOrder}
                selectedSlotOrder={selectedSlotOrder}
              />
            }
            stats={slot.stats}
          />
        ) : (
          <EmptySlot
            selectedSlotOrder={selectedSlotOrder}
            sidebarArea={
              <SelectCircle
                slotOrder={slotIndex + 1}
                selectedSlotOrder={selectedSlotOrder}
              />
            }
          />
        )
      )}
      attackTypes={roster.attackTypes}
      weaknesses={roster.weaknesses}
    />
  )
}
